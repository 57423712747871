<template>
  <div class="mx-64 my-8">
    <ol v-for="(faq, index) in FAQs" :key="index" class="">
      <li class="text-2xl font-medium mt-4">
        {{ index + 1 }}.
        {{ faq.title }}
        <div
          class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
          @click="toggleQuestion(index)"
          :class="[faq.data.show ? 'text-green-1' : '']"
        >
          <font-awesome-icon
            icon="fa-solid fa-circle-chevron-up"
            v-if="faq.data.show"
          />
          <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
          <p>{{ faq.data.question }}</p>
        </div>
        <div v-html="faq.data.answer" v-if="faq.data.show"></div>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: "GeneralInfoFAQ",
  data() {
    return {
      FAQs: [
        {
          title: "PAYING LESS/MORE TO BOOKING INVOICE",
          data: {
            show: true,
            question:
              "What should I do if the transfer amount I entered does not match?",
            answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                        The transfer amount that does not match will be automatically returned to your TripwePay account.
                        </p>
                    </div>`,
          },
        },
        {
          title: "OUT OF STOCK",
          data: {
            show: false,
            question:
              "What happens if I have made a payment and the stock runs out?",
            answer: `<div class="flex flex-col ml-10">
                    <p class="text-base font-normal">
                        If the order you want is out of stock, the payment that has been paid will be sent back to your TripwePay account with an estimated time of 3-5 working days.
                    </p>
                    </div>`,
          },
        },
      ],
    };
  },
  created() {
    if (this.$route.params.id == 1) {
      this.FAQs[0].data.show = true;
      this.FAQs[1].data.show = false;
    } else if (this.$route.params.id == 2) {
      this.FAQs[0].data.show = false;
      this.FAQs[1].data.show = true;
    }
  },
  methods: {
    toggleQuestion(index) {
      this.FAQs[index].data.show = !this.FAQs[index].data.show;
    },
  },
};
</script>
